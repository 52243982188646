<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="categoriesLoading">
			<v-row class="pa-11 pb-0 justify-start align-center">
				<h3>Categories</h3>
				<v-dialog v-model="dialogNewCategory" max-width="550px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="pink" fab small class="mx-3">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<NewCategory 
						@NewCategoryAdded="updateCategories"
						@CloseDialogNewCat="dialogNewCategory = false"
					/>
				</v-dialog>
			</v-row>
			
			<v-simple-table class="pa-4">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Name</th>
							<th class="text-left">Sub Categories</th>
							<th class="text-left"></th>
						</tr>
					</thead>
					
					<tbody>
						<EditCategories
							v-for="(category) in categories" 
							:key="category.id"
							:category="category" 
							@CategoryDeleted="fetchCategories"
						/>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</v-col>
</template>

<script>
import NewCategory from './NewCategory.vue';
import EditCategories from './EditCategories.vue';

export default {
	components: {
		NewCategory,
		EditCategories
	},

	data() {
		return {
			dialogNewCategory: false,
			categoriesLoading: true,
			categories: [],
		}
	},

	async mounted() {
		await this.fetchCategories();
	},

	methods: {
		async updateCategories() {
			this.dialogNewCategory = false;
			await this.fetchCategories();
		},

		fetchCategories: async function() {
			try {
				const request = await fetch(this.$store.state.base_url + '/categories', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				const res = await request.json();

				if (request.ok && request.status === 200) {
					if (res.categories) {
						this.categories = res.categories;
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.categoriesLoading = false;
			}
		},
	},
}
</script>